<template>
  <div class="modal fade" id="notificationEdit" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered   ">
          <Form @submit="onSubmit" :validation-schema="schema">

              <div class="modal-content">
                  <div class="modal-header">
                      <h1 class="modal-title fs-5">Update Notification</h1>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body ">
                      <div class="overflow-hidden">
                          <div class="float-left p-2 h-20 w-[800px]">
                              Notification:
                              <v-text-field v-model="notification" variant="outlined" density="compact"
                                  rounded="0"></v-text-field>
                          </div>
                      </div>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                      <button type="submit" :disabled="buttonDisableFlag" class="btn btn-primary">Update</button>
                  </div>
              </div>
          </Form>

      </div>
  </div>
</template>



<script setup>
import { ref, defineEmits, defineProps, watchEffect } from "vue";
import { useAdminUserStore } from "@/stores";
import { Form } from 'vee-validate';
const props = defineProps({
    notification: {
        type: String,
        required: true
    },
})
const notification = ref("");


const buttonDisableFlag = ref(false)
const emit = defineEmits(['updated'])

watchEffect(() => {
  notification.value = props?.notification;
})

const adminUserStore = useAdminUserStore();

const onSubmit = () => {
  const createRequest = {
    notification: notification.value,
  };
  buttonDisableFlag.value = true
  adminUserStore.saveNotification(createRequest).then(() => {
    buttonDisableFlag.value = false
    emit('updated')
  });
};

</script>
<style>
.v-textarea .v-field--no-label textarea, .v-textarea .v-field--active textarea {
  font-size: 12px;
}
</style>