import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '..';
import { updateLicenses } from '../account.store';

const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;

export const useAdminUserStore = defineStore('admin-user', {
    state: () => ({
        Users:[],
        commission: 0
    }),
    actions: {
        async loadUsers() {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.get(`${baseUrl}/admin/load-users`);
                this.Users = res.totalUsers;
            } catch (error) {
                alertStore.error(error);
            }
        },
        async loadResellerUsers() {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.get(`${baseUrl}/admin/load-reseller-users`);
                this.Users = res.totalUsers;
            } catch (error) {
                alertStore.error(error);
            }
        },
        async loadReferrerUsers() {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.get(`${baseUrl}/admin/load-referrer-users`);
                this.Users = res.totalUsers;
                this.commission = res.commission;
            } catch (error) {
                alertStore.error(error);
            }
        },
        async saveCommission(data) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/admin/savecommission`, {data});
                this.commission = res.commission;
                alertStore.success('Updated referral commission')
            } catch (error) {
                alertStore.error(error);
            }
        },
        async saveSiteInfo(data) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/admin/savemeta`, {title: data.title, description: data.description});
                alertStore.success('Updated site information')
            } catch (error) {
                alertStore.error(error);
            }
        },
        async saveNotification(data) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/admin/savenotification`, {notification: data.notification});
                alertStore.success('Updated site information')
            } catch (error) {
                alertStore.error(error);
            }
        },
        async createUser(createRequest) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/user/create`,{createRequest});
                alertStore.success('Created New User')
                return
            } catch (error) {
                alertStore.error(error);
            }
        },
        async addResellerUser(createRequest) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/user/add-reseller`,{createRequest});
                alertStore.success('Add Reseller')
                return
            } catch (error) {
                alertStore.error(error);
            }
        },
        async updateUser(updateRequest) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/user/update`,{updateRequest});
                alertStore.success('Updated User')
                return
            } catch (error) {
                alertStore.error(error);
            }
        },
        async updateUserBalance(updateRequest) {
            const alertStore = useAlertStore();
            try {
                const result = await fetchWrapper.post(`${baseUrl}/admin/user/update-balance`,{updateRequest});
                this.Users = this.Users.map(item => {
                    if(item.uuid === result.result.uuid) {
                        return result.result
                    }
                    return item
                })
                alertStore.success('Added Balance')
                return
            } catch (error) {
                alertStore.error(error);
            }
        },
        async refundUser(updateRequest) {
            const alertStore = useAlertStore();
            try {
                const result = await fetchWrapper.post(`${baseUrl}/admin/user/refund-balance`,{updateRequest});
                this.Users = this.Users.map(item => {
                    if(item.uuid === result.result.uuid) {
                        return result.result
                    }
                    return item
                })
                alertStore.success('Refunded Balance')
                return
            } catch (error) {
                alertStore.error(error);
            }
        },
        async deleteUsers(uuids) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/user/delete`, { uuids });
                updateLicenses()
                alertStore.success("Delete successful")
                
            } catch (error) {
                alertStore.error(error);
            }
        },
        async deleteResellers(uuids) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/user/delete-reseller`, { uuids });
                updateLicenses()
                alertStore.success("Delete successful")
                
            } catch (error) {
                alertStore.error(error);
            }
        },
    },
});