<template>
    <div class="">
        <div class="flex justify-between flex-wrap items-center my-2">
            <div class="w-1/5 min-w-[200px] pr-5 text-xs">
                Search (Email, User Name, Full Name, Address, City, Country)
                <v-text-field placeholder="Name" variant="outlined" density="compact" v-model="searchName" rounded="0">
                </v-text-field>
            </div>
            <div>
                <v-btn icon="mdi-delete" size="small" style="outline: none;" @click="deleteCustomers"
                    class="mr-5 bg-dark text-light"></v-btn>
                <v-btn icon="mdi-plus" size="small" style="outline: none;" @click="addNewUser" data-bs-toggle="modal"
                    data-bs-target="#customerAddModal" class="bg-dark text-light"></v-btn>
            </div>
        </div>
        <v-data-table :headers="headers" :items="Users.filter(u => [u.email, u.user_name, u.name, u.address, u.city, u.country].join(',').toLowerCase().includes(searchName.toLowerCase()))" 
            item-key="name" class="border h-[500px] bg-white text-black min-h-[500px]" 
            items-per-page="-1"
            style="font-size: 13px;" :style="`height: ${computedHeight}px;`">
            <template v-slot:[`item.no`]="{ index }">
                {{ index + 1 }}
            </template>
            <template v-slot:[`item.checkbox`]="{ item }">
                <div @click="toggleSelection(item)" class="ml-auto align-self-center"
                    style="width: 20px; height: 20px; cursor: pointer;">
                    <input :checked="isSelected(item)" class="form-check-input shadow-none" type="checkbox" />

                </div>
            </template>
            <template v-slot:[`item.licenses`]="{ item }">
                <router-link
                    class="text-white"
                    :to="{ name: 'AdminLicensePage', query: { email: item.email }}"
                >
                    View
                </router-link>
            </template>
            <template v-slot:[`item.tglink`]="{ item }">
                <a v-if="item.tglink" class="flex flex-row items-center gap-x-2 px-3" :href="formatTgLink(item.tglink)" target="_blank">
                    <img class="w-[30px] h-[30px]" src="../../../assets/tg.png" />
                </a>
            </template>
            <template v-slot:[`item.buttonBar`]="{ item }">
                <v-menu offset-y location="bottom">
                    <template v-slot:activator="{ props, on, isActive }">
                        <v-btn v-bind="props" v-on="{ ...on }" color="#234" density="compact"
                            :style="isActive ? 'border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;' : ''"
                            style="outline: none; font-size: 10px; " class="w-32 z-10 bg-dark text-light">
                            Actions
                            <v-icon icon="mdi-chevron-down" size="14"></v-icon>
                        </v-btn>
                    </template>
                    <v-list class="w-32 flex-1 flex flex-col px-0 py-0  z-0"
                        style="border-top-left-radius: 0px;border-top-right-radius: 0px">
                        <v-btn v-for="(button, index) in actionButtons" :key="index" @click="clickAction(item, button)"
                            rounded="0" class="text-center bg-dark text-light"
                            style="font-size: 10px; width: 128px; outline: none; border-bottom: 1px solid #345;"
                            :data-bs-toggle="(button === 'Update' || button === 'Topup or Refund') && 'modal'"
                            :data-bs-target="button === 'Update' ? '#customerUpdateModal' : button === 'Topup or Refund' ? '#refundModal': ''">
                            {{
                    button }}
                        </v-btn>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
    </div>
    <div>
        <AddNewOneDialog @close="addNewOneDialogFlag = false" @updated="updatedHandler" />
        <UpdateInfoDialog @close="updateInfoDialogFlag = false" :item="selectedItem" @updated="updatedHandler" />
        <RefundDialog @close="refundDialogFlag = false" :item="selectedItem" @updated="updatedHandler" />

    </div>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useAdminUserStore } from "@/stores"
import { storeToRefs } from 'pinia';
import AddNewOneDialog from "./Modals/AddNewOneDialog.vue"
import UpdateInfoDialog from "./Modals/UpdateInfoDialog.vue"
import RefundDialog from "./Modals/RefundDialog.vue"

const headers = ref(
    [
        { title: 'No', align: 'center', key: 'no' },
        { title: '', align: 'center', key: 'checkbox' },
        { title: 'User Name', align: 'center', key: 'user_name' },
        { title: 'Full Name', align: 'center', key: 'name' },
        { title: 'Email', align: 'center', key: 'email' },
        { title: 'Role', align: 'center', key: 'role' },
        { title: 'Balance', align: 'center', key: 'balance' },
        { title: 'Address', align: 'center', key: 'address' },
        { title: 'City', align: 'center', key: 'city' },
        { title: 'Country', align: 'center', key: 'country' },
        { title: 'Telegram', align: 'center', key: 'tglink' },
        { title: 'Licenses', align: 'center', key: 'licenses'},
        { title: 'Actions', align: 'center', key: 'buttonBar' },
    ]
);

const addNewOneDialogFlag = ref(false)
const updateInfoDialogFlag = ref(false)
const refundDialogFlag = ref(false)
const searchName = ref("")


const adminUserStore = useAdminUserStore()
const { Users } = storeToRefs(adminUserStore)
adminUserStore.loadUsers()
const selectedItems = ref([]);
const selectedItem = ref(null);

const offsetHeight = 300; // Adjust this value as needed

const computedHeight = ref(window.innerHeight - offsetHeight);

const handleResize = () => {
    computedHeight.value = window.innerHeight - offsetHeight;
};
const formatTgLink = (tglink) => {
    if(tglink) {
    const cleanedID = tglink.replace(/(@|https?:\/\/t\.me\/)/g, "").trim();
    const telegramUrl = `https://t.me/${cleanedID}`;
    return telegramUrl;
    } else return ''
}
onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

const isSelected = (item) => {
    return selectedItems.value.includes(item); 
};

const toggleSelection = (item) => {
    const index = selectedItems.value.indexOf(item);
    if (index === -1) {
        selectedItems.value.push(item);
    } else {
        selectedItems.value.splice(index, 1);
    }
};




const addNewUser = () => {
    addNewOneDialogFlag.value = true
}
const actionButtons = ref(
    ["Update", "Topup or Refund"]
)
const clickAction = (item, button) => {
    selectedItem.value = item
    switch (button) {
        case "Update":
            updateInfoDialogFlag.value = true
            break
        case "Topup or Refund":
            refundDialogFlag.value = true
            break


    }
}

const updatedHandler = () => {
    addNewOneDialogFlag.value = false
    updateInfoDialogFlag.value = false
    refundDialogFlag.value = false
    adminUserStore.loadUsers()
}

const deleteCustomers = () => {
    adminUserStore.deleteUsers(selectedItems.value.map(item => item.uuid)).then(() => {
        adminUserStore.loadUsers()
    })

}
</script>