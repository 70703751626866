<template>
    <div class="modal fade" id="refundModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  w-[500px] ">
            <Form @submit="onSubmit" :validation-schema="schema" class="w-full">

                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5">Balance Update</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body ">
                        <div class="overflow-hidden">
                            <div class="w-1/2 float-left px-2 py-2 h-20 ">
                                Time Unit:
                                <div>
                                    <v-btn-toggle v-model="bType" color="deep-purple-accent-3" group mandatory
                                        rounded="0" style="height: 40px;" class="border w-full">
                                        <v-btn value="TOPUP" class="w-1/2 bg-white text-black h-full"
                                            style="outline: none; height: 100%;">
                                            <p class="text-xs">Topup</p>
                                        </v-btn>
                                        <v-btn value="REFUND" class="w-1/2 bg-white text-black h-full"
                                            style="outline: none; height: 100%;">
                                            <p class="text-xs">Refund</p>
                                        </v-btn>
                                    </v-btn-toggle>
                                </div>
    
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Amount:
                                <v-text-field v-model="amount" variant="outlined" density="compact" type="number"
                                    rounded="0"></v-text-field> 
                            </div>
                            <div class="float-left w-full p-2 h-20">
                            Comment:
                            <v-text-field v-model="comment" variant="outlined" density="compact"
                                rounded="0"></v-text-field> 
                        </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" :disabled="buttonDisableFlag" class="btn btn-primary">Update</button>
                    </div>
                </div>
            </Form>

        </div>
    </div>
</template>



<script setup>
import { ref, defineEmits, defineProps } from "vue";
import { useAdminUserStore } from "@/stores";
import * as Yup from 'yup';
import { Form, Field } from 'vee-validate';

const comment = ref("");
const amount = ref(0)

const props = defineProps({
    item: {
        type: Object, // Assuming user object has a name property
        required: true
    },
});

const buttonDisableFlag = ref(false)
const bType = ref("TOPUP")

const emit = defineEmits(['inInput', 'submit'])

const adminUserStore = useAdminUserStore();

const onSubmit = (values) => {
  const createRequest = {
    uuid: props.item.uuid,
    balance: amount.value,
    comment: comment.value,
    bType: bType.value
  };
  buttonDisableFlag.value = true
  adminUserStore.refundUser(createRequest).then(() => {
    buttonDisableFlag.value = false
    emit('updated')
  });
};

</script>
