<template>
    <div class="modal fade" id="customerUpdateModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  w-[500px] ">
            <Form @submit="onSubmit">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5">Update User</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body ">
                        <div class="overflow-hidden">
                            <div class="float-left w-1/2 p-2 h-20">
                                User Name:
                                <v-text-field v-model="userName" variant="outlined" density="compact"
                                    rounded="0"></v-text-field>
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Full Name:
                                <v-text-field v-model="fullName" variant="outlined" density="compact"
                                    rounded="0"></v-text-field>
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Email:
                                <v-text-field v-model="email" variant="outlined" density="compact"
                                    rounded="0"></v-text-field>
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Role:
                                <v-select v-model="role" :items="roleOptions" variant="outlined" density="compact"
                                    rounded="0"></v-select>
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Balance:
                                <v-text-field v-model="balance" variant="outlined" density="compact" rounded="0"
                                    type="number"></v-text-field>
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Address:
                                <v-text-field v-model="address" variant="outlined" density="compact"
                                    rounded="0"></v-text-field>
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Country:
                                <country-select v-bind="field" name="country" :country="country" topCountry="US"
                                    :countryName="true" v-model="country"
                                    class="text-xs bg-gray outline-none w-full border text-black select-border px-[20px]"
                                    style="height: 44px;" />
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                City:
                                <region-select v-bind="field" name="city" :country="country" v-model="city"
                                    :region="city" :countryName="true" :regionName="true"
                                    class="text-xs bg-gray outline-none w-full border text-black select-border px-[20px]"
                                    style="height: 44px;" />
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Password:
                                <Field name="password" v-slot="{ field }">
                                    <v-text-field v-bind="field" variant="outlined"
                                        type="password"
                                        density="compact" rounded="0" placeholder="Your Password"
                                        class="custom-text-field"></v-text-field>
                                </Field>
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Confirm Password:
                                <Field name="confirm_password" v-slot="{ field }">
                                    <v-text-field v-bind="field" variant="outlined" type="password" density="compact"
                                        rounded="0" :active="true" placeholder="Confirm Your Password"
                                        class="custom-text-field"></v-text-field>
                                </Field>
                            </div>
                            <div class="float-left w-1/2 p-2 h-20">
                                Telegram:
                                <v-text-field v-model="tglink" variant="outlined" density="compact" placeholder="https://t.me/<user>"
                                    rounded="0"></v-text-field>
                            </div>
                            <div class="float-left w-full p-2 h-20">
                                Telegram:
                                <v-text-field v-model="tglink" variant="outlined" density="compact" placeholder="https://t.me/<user>"
                                    rounded="0"></v-text-field>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" :disabled="buttonDisableFlag" class="btn btn-primary">Update</button>
                    </div>
                </div>
            </Form>

        </div>
    </div>
</template>



<script setup>
import { ref, watchEffect, defineProps, defineEmits } from "vue";
import { useAdminUserStore } from "@/stores";
import { Form, Field } from 'vee-validate';



const props = defineProps({
    item: {
        type: Object, // Assuming user object has a name property
        required: true
    },
});

const userName = ref(null);
const fullName = ref(null);
const email = ref(null);
const role = ref(null);
const balance = ref(0);
const country = ref(null);
const city = ref(null);
const address = ref(null);
const tglink = ref(null);
const buttonDisableFlag = ref(false)
const previousBalance = ref(0)

watchEffect(() => {
    if (props.item) {
        userName.value = props.item.user_name
        fullName.value = props.item.name
        email.value = props.item.email
        role.value = props.item.role
        balance.value = parseFloat(props.item.balance)
        country.value = props.item.country
        city.value = props.item.city
        address.value = props.item.address
        tglink.value = props.item.tglink
        previousBalance.value = parseFloat(props.item.balance)
    }
}, [props.item])
const emit = defineEmits(['inInput', 'submit'])

const roleOptions = ref([
    'admin',
    'user',
]);
const adminUserStore = useAdminUserStore();

const onSubmit = (values) => {
    const updateRequest = {
        uuid: props.item.uuid,
        userName: userName.value,
        fullName: fullName.value,
        email: email.value,
        role: role.value,
        balance: balance.value,
        country: country.value,
        city: city.value,
        address: address.value,
        password: values.password,
        tglink: tglink.value
    };
    buttonDisableFlag.value = true
    adminUserStore.updateUser(updateRequest).then(res => {
        buttonDisableFlag.value = false
        emit('updated')
    });
};


watchEffect(()=>{
    balance.value = Math.max(0,balance.value)
},[balance.value])
</script>

<style scoped>
.clear-both {
    clear: both;
}
</style>